// const jquery = require('jquery');
// window.$ = window.jQuery = jquery;
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'
import { CountUp } from 'countup.js/dist/countUp.js';

$(function () {

  var controller = new ScrollMagic.Controller();

  // Animate Timeline
  var timelineWidth = $('.timeline-wrapper')[0].scrollWidth;
  var timelineContainerWidth = $('.timeline-container').width();
  // console.log('width', timelineWidth - timelineContainerWidth);
  var width = timelineWidth - timelineContainerWidth;
  var horizontalSlide = new TimelineMax().to(".timeline-wrapper", 1, {
    x: "-" + width +"px"
  });
  new ScrollMagic.Scene({
    triggerElement: ".timeline",
    triggerHook: 0,
    duration: 1000
  })
  // .addIndicators()
  .setPin(".timeline")
  .setTween(horizontalSlide)
  .addTo(controller);

  // Create Tabby tabs
  var company = new Tabby('.company-tabs');
  var individual = new Tabby('.individual-tabs');

  // Animate DTA chart
  new ScrollMagic.Scene({
    triggerElement: ".pink-bkg",
    triggerHook: .5
  })
  .reverse(false)
  .setClassToggle('.dta-donut .chart', 'dta-donut-animation')
  .addTo(controller);

  // Animate FAF chart
  new ScrollMagic.Scene({
    triggerElement: ".pink-bkg",
    triggerHook: .5
  })
  .reverse(false)
  .setClassToggle('.faf-donut .chart', 'faf-donut-animation')
  .addTo(controller);

  // Add FadeIn animations
  $('.animate__animated').each(function(e){
    new ScrollMagic.Scene({
      triggerElement: this
    })
    .reverse(false)
    .setClassToggle(this, 'animate__fadeIn')
    .addTo(controller);
  });

  // Count up to number
  new ScrollMagic.Scene({
    triggerElement: ".pledges"
  })
  .reverse(false)
  .on('start', function(){
    let demo = new CountUp('countUp', 521860);
  if (!demo.error) {
    demo.start();
  } else {
    console.error(demo.error);
  }
  })
  .addTo(controller);


  // Animate Revenue Chart
  $('svg.revenue-chart circle').each(function(e){
    new ScrollMagic.Scene({
      triggerElement: 'svg.revenue-chart',
      triggerHook: .25
    })
    .reverse(false)
    .setTween(new TimelineMax().to(this, 1, {strokeDasharray: this.getAttribute('data-dasharray')}))
    .addTo(controller);
  });

  $('.values .flex-column div').each(function(e,y){
    new ScrollMagic.Scene({
      triggerElement: '.values',
      triggerHook: .25
    })
    .reverse(false)
    .setTween(new TimelineMax().from(this, 1, {top:"-100px", delay: e/3}))
    .addTo(controller);
  });

  // Animate Revenue Chart
  $('svg.expenses-chart circle').each(function(e){
    new ScrollMagic.Scene({
      triggerElement: 'svg.expenses-chart',
      triggerHook: .25
    })
    .reverse(false)
    .setTween(new TimelineMax().to(this, 1, {strokeDasharray: this.getAttribute('data-dasharray')}))
    .addTo(controller);
  });

  // Init Gumshoe nav
  var spy = new Gumshoe('#dot-nav a');

  // Init tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Video Modals
  function autoPlayYouTubeModal(){
    var trigger = $("body").find('[data-video]');

    trigger.click(function() {

      var theModal = $(this).attr( "data-target" ),
      videoSRC = $(this).attr( "data-video" ),
      videoSRCauto = videoSRC+"?autoplay=1" ;
      $(theModal+' iframe').attr('src', videoSRCauto);

      $(theModal).on('hidden.bs.modal', function(){
        $(theModal+' iframe').attr('src', videoSRC);
      })
    });
  }

  $(document).ready(function(){
    autoPlayYouTubeModal();
  });

}); // End
